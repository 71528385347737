import moment from 'moment-timezone';

export const checkCache = () => {
  const idmCache = localStorage.getItem('idmReport');
  if (idmCache) {
    try {
      const caches = JSON.parse(idmCache);
      const todayCache = caches.filter((cache: any) => {
        const time = moment(cache.time);
        return time.isSame(moment(), 'hour');
      });
      localStorage.setItem('idmReport', JSON.stringify(todayCache));
    } catch (error) {
      localStorage.setItem('idmReport', '{}');
    }
  }
};
