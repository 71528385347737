import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './index';
import { PanelDetailType } from '@/interface/panelTypes';
import { FetchStatus } from '@/interface/common';

export type DataPolarity = {
  timeStamp: number;
  positive: number;
  negative: number;
  insufficient: number;
  all: number;
};

export type BuildingReport = {
  buildingId: string;
  buildingName: string;
  buildingSlug: string;
  timezone: string;
  averageNetWorkUptime: number;
  averageDataUptime: number;
  urgentIssues: number;
  cxApiStatus: FetchStatus;
  statusApiStatus: FetchStatus;
  dataUptimeApiStatus: FetchStatus;
  healthApiStatus: FetchStatus;
  dataApiStatus: FetchStatus;
  panelReport: PanelReport[];
};

export type PanelReport = {
  id: number;
  panelName: string;
  dataGateWay: string;
  connectivityStatus?: string;
  connectivityLastReportAt?: number;
  connectivityType?: string;
  sdCard?: boolean;
  ipAddress: string;
  publicAddress: string;
  ethernetAddress: string;
  wlan0Mac: string;
  eth0Mac: string;
  softap0Mac: string;
  ctChainStatus?: {
    chain1: boolean;
    chain2: boolean;
  };
  ctUtilization?: [number, number[]];
  ctSize?: number[];
  dataPolarity?: DataPolarity;
  dataUptime?: number;
  networkUptime?: number;
  issues: IssuesType[];
  statusAPIStatus: FetchStatus;
  healthApiStatus: FetchStatus;
  lastDataReceivedAt: string;
  start: string;
  end: string;
};

export enum IssuesType {
  InternetConnectivity = 'internetConnectivityIssue',
  CTChain = 'cTChainIssue',
  CTUtilization = 'cTUtilizationIssue',
  CTTypeMismatch = 'cTTypeMismatch',
  SDCard = 'sDCardIssue',
  NegativeData = 'negativeData',
  InsufficientData = 'insufficientData',
  DataUptime = 'dataUptimeIssue',
}
export interface IdmStateInterface {
  idmAccount: string;
  idmBlds: IDMBldType[];
  selectedBlds: string[];
  selectedPanels: string[];
  selectedIssuesTypes: IssuesType[];
  fetchStatus: FetchStatus;
  statusAPIStatus: {
    [key: string]: FetchStatus;
  };
  dataUptimeAPIStatus: {
    [key: string]: FetchStatus;
  };
  dataAPIStatus: {
    [key: string]: FetchStatus;
  };
}

export type IDMBldType = {
  buildingId: string;
  buildingName: string;
  buildingSlug: string;
  timezone: string;
  panels: PanelDetailType[];
};

export const initialState: IdmStateInterface = {
  idmAccount: '',
  idmBlds: [],
  selectedBlds: [],
  selectedPanels: [],
  selectedIssuesTypes: [],
  fetchStatus: FetchStatus.Idle,
  statusAPIStatus: {},
  dataUptimeAPIStatus: {},
  dataAPIStatus: {},
};

const idmSlice = createSlice({
  name: 'idm',
  initialState,
  reducers: {
    setIdmAccount(state, action: PayloadAction<string>) {
      state.idmAccount = action.payload;
    },
    setIdmBlds(state, action: PayloadAction<IDMBldType[]>) {
      state.idmBlds = [...action.payload];
    },
    setIdmSelectedBlds(state, action: PayloadAction<string[]>) {
      state.selectedBlds = [...action.payload];
    },
    setIdmSelectedPanels(state, action: PayloadAction<string[]>) {
      state.selectedPanels = [...action.payload];
    },
    setIdmIssuesTypes(state, action: PayloadAction<IssuesType[]>) {
      state.selectedIssuesTypes = action.payload;
    },
    setFetchStatus(state, action: PayloadAction<FetchStatus>) {
      state.fetchStatus = action.payload;
    },
    setStatusAPIStatus(state, action: PayloadAction<{ [key: string]: FetchStatus }>) {
      state.statusAPIStatus = {
        ...state.statusAPIStatus,
        ...action.payload,
      };
    },
    setDataAPIStatus(state, action: PayloadAction<{ [key: string]: FetchStatus }>) {
      state.dataAPIStatus = {
        ...state.dataAPIStatus,
        ...action.payload,
      };
    },
  },
});

export const {
  setIdmAccount,
  setIdmBlds,
  setIdmSelectedBlds,
  setIdmSelectedPanels,
  setIdmIssuesTypes,
  setFetchStatus,
  setStatusAPIStatus,
  setDataAPIStatus,
} = idmSlice.actions;
export const selectIdmAccount = (state: RootState) => state.idm.idmAccount;
export const selectIdmBlds = (state: RootState) => state.idm.idmBlds;
export const selectSelectedBlds = (state: RootState) => state.idm.selectedBlds;
export const selectSelectedPanels = (state: RootState) => state.idm.selectedPanels;
export const selectSelectedIssuesTypes = (state: RootState) => state.idm.selectedIssuesTypes;
export const selectFetchStatus = (state: RootState) => state.idm.fetchStatus;
export const selectStatusAPIStatus = (state: RootState) => state.idm.statusAPIStatus;
export const selectDataAPIStatus = (state: RootState) => state.idm.dataAPIStatus;
export default idmSlice.reducer;
